import { isPoint, isLineString, isPolygon, isMultiPoint, isMultiLineString, isMultiPolygon, conformLineString, conformRing, conformPolygon, conformMultiLineString, conformMultiPolygon } from './geometry.js';
// Assert
export function isGeojsonPoint(input) {
    return (typeof input === 'object' &&
        input !== null &&
        input.type === 'Point' &&
        isPoint(input.coordinates));
}
export function isGeojsonLineString(input) {
    return (typeof input === 'object' &&
        input !== null &&
        input.type === 'LineString' &&
        isLineString(input.coordinates));
}
export function isGeojsonPolygon(input) {
    return (typeof input === 'object' &&
        input !== null &&
        input.type === 'Polygon' &&
        Array.isArray(input.coordinates) &&
        isPolygon(input.coordinates));
}
export function isGeojsonMultiPoint(input) {
    return (typeof input === 'object' &&
        input !== null &&
        input.type === 'MultiPoint' &&
        isMultiPoint(input.coordinates));
}
export function isGeojsonMultiLineString(input) {
    return (typeof input === 'object' &&
        input !== null &&
        input.type === 'MultiLineString' &&
        isMultiLineString(input.coordinates));
}
export function isGeojsonMultiPolygon(input) {
    return (typeof input === 'object' &&
        input !== null &&
        input.type === 'MultiPolygon' &&
        Array.isArray(input.coordinates) &&
        isMultiPolygon(input.coordinates));
}
export function isGeojsonGeometry(obj) {
    const isObject = typeof obj === 'object' && obj !== null;
    const hasStringType = isObject && 'type' in obj && typeof obj.type === 'string';
    const isValidType = hasStringType &&
        (obj.type === 'Point' ||
            obj.type === 'LineString' ||
            obj.type === 'Polygon' ||
            obj.type === 'MultiPoint' ||
            obj.type === 'MultiLineString' ||
            obj.type === 'MultiPolygon');
    const hasCoordinatesArray = isObject && 'coordinates' in obj && Array.isArray(obj.coordinates);
    return isValidType && hasCoordinatesArray;
}
// Convert to Geometry
export function convertGeojsonPointToPoint(geojsonPoint) {
    return geojsonPoint.coordinates;
}
export function convertGeojsonLineStringToLineString(geojsonLineString) {
    return conformLineString(geojsonLineString.coordinates);
}
export function convertGeojsonPolygonToRing(geojsonPolygon, close = false) {
    let outerRing = geojsonPolygon.coordinates[0];
    outerRing = conformRing(outerRing);
    return close ? [...outerRing, outerRing[0]] : outerRing;
}
export function convertGeojsonPolygonToPolygon(geojsonPolygon, close = false) {
    let polygon = geojsonPolygon.coordinates;
    polygon = conformPolygon(polygon);
    return close ? polygon.map((ring) => [...ring, ring[0]]) : polygon;
}
export function convertGeojsonMultiPointToMultiPoint(geojsonMultiPoint) {
    return geojsonMultiPoint.coordinates;
}
export function convertGeojsonMultiLineStringToMultiLineString(geojsonMultiLineString) {
    return conformMultiLineString(geojsonMultiLineString.coordinates);
}
export function convertGeojsonMultiPolygonToMultiPolygon(geojsonMultiPolygon, close = false) {
    let multipolygon = geojsonMultiPolygon.coordinates;
    multipolygon = conformMultiPolygon(multipolygon);
    return close
        ? multipolygon.map((polygon) => polygon.map((ring) => [...ring, ring[0]]))
        : multipolygon;
}
export function convertGeojsonGeometryToGeometry(geojsonGeometry) {
    if (isGeojsonPoint(geojsonGeometry)) {
        return convertGeojsonPointToPoint(geojsonGeometry);
    }
    else if (isGeojsonLineString(geojsonGeometry)) {
        return convertGeojsonLineStringToLineString(geojsonGeometry);
    }
    else if (isGeojsonPolygon(geojsonGeometry)) {
        return convertGeojsonPolygonToPolygon(geojsonGeometry);
    }
    else if (isGeojsonMultiPoint(geojsonGeometry)) {
        return convertGeojsonMultiPointToMultiPoint(geojsonGeometry);
    }
    else if (isGeojsonMultiLineString(geojsonGeometry)) {
        return convertGeojsonMultiLineStringToMultiLineString(geojsonGeometry);
    }
    else if (isGeojsonMultiPolygon(geojsonGeometry)) {
        return convertGeojsonMultiPolygonToMultiPolygon(geojsonGeometry);
    }
    else {
        throw new Error('Geometry type not supported');
    }
}
// Expand
export function expandGeojsonMultiPointToGeojsonPointArray(geojsonMultiPoint) {
    return geojsonMultiPoint.coordinates.map((point) => {
        return {
            type: 'Point',
            coordinates: point
        };
    });
}
export function expandGeojsonMultiLineStringToGeojsonLineStringArray(geojsonMultiLineString) {
    return geojsonMultiLineString.coordinates.map((lineString) => {
        return {
            type: 'LineString',
            coordinates: lineString
        };
    });
}
export function expandGeojsonMultiPolygonToGeojsonPolygonArray(geojsonMultiPolygon) {
    return geojsonMultiPolygon.coordinates.map((polygon) => {
        return {
            type: 'Polygon',
            coordinates: polygon
        };
    });
}
export function expandGeojsonMultiGeometryToGeojsonGeometryArray(geojsonMultiGeometry) {
    if (isGeojsonMultiPoint(geojsonMultiGeometry)) {
        return expandGeojsonMultiPointToGeojsonPointArray(geojsonMultiGeometry);
    }
    else if (isGeojsonMultiLineString(geojsonMultiGeometry)) {
        return expandGeojsonMultiLineStringToGeojsonLineStringArray(geojsonMultiGeometry);
    }
    else if (isGeojsonMultiPolygon(geojsonMultiGeometry)) {
        return expandGeojsonMultiPolygonToGeojsonPolygonArray(geojsonMultiGeometry);
    }
    else {
        throw new Error('Geometry type not supported');
    }
}
// Join
export function joinGeojsonPointArrayToGeojsonMultiPoint(geojsonPointArray) {
    return {
        type: 'MultiPoint',
        coordinates: geojsonPointArray.map((geojsonPoint) => geojsonPoint.coordinates)
    };
}
export function joinGeojsonLineStringArrayToGeojsonMultiLineString(geojsonLineStringArray) {
    return {
        type: 'MultiLineString',
        coordinates: geojsonLineStringArray.map((geojsonLineString) => geojsonLineString.coordinates)
    };
}
export function joinGeojsonPolygonArrayToGeojsonMultiPolygon(geojsonPolygonArray) {
    return {
        type: 'MultiPolygon',
        coordinates: geojsonPolygonArray.map((geojsonPolygon) => geojsonPolygon.coordinates)
    };
}
export function joinGeojsonGeometryArrayToGeojsonMultiGeometry(geojsonGeometryArray) {
    if (geojsonGeometryArray.every(isGeojsonPoint)) {
        return joinGeojsonPointArrayToGeojsonMultiPoint(geojsonGeometryArray);
    }
    else if (geojsonGeometryArray.every(isGeojsonLineString)) {
        return joinGeojsonLineStringArrayToGeojsonMultiLineString(geojsonGeometryArray);
    }
    else if (geojsonGeometryArray.every(isGeojsonPolygon)) {
        return joinGeojsonPolygonArrayToGeojsonMultiPolygon(geojsonGeometryArray);
    }
    else {
        throw new Error('Geometry type not supported');
    }
}
// Convert to SVG
export function convertGeojsonToSvg(geometry) {
    if (geometry.type === 'Point') {
        return {
            type: 'circle',
            coordinates: geometry.coordinates
        };
    }
    else if (geometry.type === 'LineString') {
        return {
            type: 'polyline',
            coordinates: geometry.coordinates
        };
    }
    else if (geometry.type === 'Polygon') {
        return {
            type: 'polygon',
            coordinates: geometry.coordinates[0]
        };
    }
    else {
        throw new Error(`Unsupported GeoJSON geometry`); // MultiPolygons not supported in SVG
    }
}
// Wrap
export function geometryToFeature(geometry, properties) {
    return {
        type: 'Feature',
        properties: properties ? properties : {},
        geometry: geometry
    };
}
export function featuresToFeatureCollection(features) {
    if (!Array.isArray(features)) {
        features = [features];
    }
    return {
        type: 'FeatureCollection',
        features: features
    };
}
export function geometriesToFeatureCollection(geometries, properties) {
    return {
        type: 'FeatureCollection',
        features: geometries.map((geometry, i) => properties
            ? geometryToFeature(geometry, properties[i])
            : geometryToFeature(geometry))
    };
}
export function featureToGeometry(feature) {
    return feature.geometry;
}
export function featureCollectionToGeometries(featureCollection) {
    return featureCollection.features.map(featureToGeometry);
}
