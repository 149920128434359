/**
 * Convert RBG to hex
 * @param {[number, number, number]} [r, g, b] - rgb, e.g. [0, 51, 255]
 * @returns {Color} hex string, e.g. '#0033ff'
 */
export function rgbToHex([r, g, b]) {
    return ('#' +
        [r, g, b]
            .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        })
            .join(''));
}
/**
 * Convert hex to RGB
 * @param {string} hex - hex string, e.g. '#0033ff'
 * @returns {Color} RGB, e.g. [0, 51, 255]
 */
export function hexToRgb(hex) {
    const bigint = parseInt(hex.replace(/^#/, ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
}
/**
 * Convert hex to fractional RGB
 * @param {string} hex - hex string, e.g. '#0033ff'
 * @returns {Color} Fractional RGB, e.g. [0, 0.2, 1]
 */
export function hexToFractionalRgb(hex) {
    return hexToRgb(hex).map((c) => c / 255);
}
