import { parseAnnotation } from './parser.js';
import { generateAnnotation } from './generator.js';
import { isAnnotation1, isMap2 } from './guards.js';
export function toAnnotation1(annotation) {
    if (isAnnotation1(annotation)) {
        return annotation;
    }
    else {
        const convertedAnnotation = generateAnnotation(parseAnnotation(annotation));
        if ('items' in convertedAnnotation) {
            return convertedAnnotation.items[0];
        }
        else {
            return convertedAnnotation;
        }
    }
}
export function toAnnotationPage1(annotationPage) {
    return {
        ...annotationPage,
        items: annotationPage.items.map(toAnnotation1)
    };
}
export function toMap2(map) {
    if (isMap2(map)) {
        return map;
    }
    else {
        const convertedMap = parseAnnotation(generateAnnotation(map));
        return convertedMap[0];
    }
}
export function toMaps2(maps) {
    return maps.map(toMap2);
}
