// Generated from Luuks color scheme with
// https://www.colors.tools/tints-and-shades/
const black = '#222222';
const white = '#ffffff';
// Original color from Luuk's design
export const originalColorIndex = 4;
const blueShades = [
    '#dff7fa',
    '#c0eff5',
    '#a1e7f0',
    '#82dfeb',
    '#63d8e6', // Original color
    '#4facb8',
    '#3b818a',
    '#27565c',
    '#132b2d'
];
const darkblueShades = [
    '#d7d9ee',
    '#b0b4de',
    '#898ecd',
    '#6269bd',
    '#3b44ad', // Original color
    '#2f368a',
    '#232867',
    '#171b45',
    '#0b0d22'
];
// TODO: add dark blue background color
// '#101655'
const purpleShades = [
    '#f3dcf0',
    '#e7b9e1',
    '#dc97d2',
    '#d074c3',
    '#c552b5', // Original color
    '#9d4190',
    '#76316c',
    '#4e2048',
    '#271024'
];
const pinkShades = [
    '#ffddf1',
    '#ffbbe3',
    '#ff99d5',
    '#ff77c7',
    '#ff56ba', // Original color
    '#cc4494',
    '#99336f',
    '#66224a',
    '#321125'
];
const orangeShades = [
    '#ffe3d0',
    '#ffc7a1',
    '#ffab72',
    '#ff8f43',
    '#ff7415', // Original color
    '#cc5c10',
    '#99450c',
    '#662e08',
    '#321704'
];
const redShades = [
    '#fededf',
    '#febebf',
    '#fe9e9f',
    '#fe7e7f',
    '#fe5e60', // Original color
    '#cb4b4c',
    '#983839',
    '#652526',
    '#321213'
];
const greenShades = [
    '#e0f2e8',
    '#c1e6d2',
    '#a2d9bb',
    '#83cda5',
    '#64c18f', // Original color
    '#509a72',
    '#3c7355',
    '#284d39',
    '#13261c'
];
const yellowShades = [
    '#fff3d9',
    '#ffe8b3',
    '#ffdd8d',
    '#ffd267',
    '#ffc742', // Original color
    '#cc9f34',
    '#997727',
    '#664f1a',
    '#32270d'
];
const grayShades = [
    '#efefef',
    '#e0e0e0',
    '#d0d0d0',
    '#c1c1c1',
    '#b2b2b2',
    '#8e8e8e',
    '#6a6a6a',
    '#474747',
    '#232323'
];
export const shades = {
    blue: blueShades,
    darkblue: darkblueShades,
    purple: purpleShades,
    pink: pinkShades,
    orange: orangeShades,
    red: redShades,
    green: greenShades,
    yellow: yellowShades,
    gray: grayShades
};
const blue = blueShades[originalColorIndex];
const darkblue = darkblueShades[originalColorIndex];
const purple = purpleShades[originalColorIndex];
const pink = pinkShades[originalColorIndex];
const orange = orangeShades[originalColorIndex];
const red = redShades[originalColorIndex];
const green = greenShades[originalColorIndex];
const yellow = yellowShades[originalColorIndex];
const gray = grayShades[originalColorIndex];
export { blue, darkblue, purple, pink, orange, red, green, yellow, gray, black, white };
