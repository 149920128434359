export async function fetchUrl(input, init, fetchFn) {
    let response;
    if (typeof fetchFn === 'function') {
        response = await fetchFn(input, init);
    }
    else {
        response = await fetch(input, init);
    }
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
}
export async function fetchJson(input, init, fetchFn) {
    const response = await fetchUrl(input, init, fetchFn);
    return await response.json();
}
export async function fetchImageInfo(imageUri, init, fetchFn) {
    return await fetchJson(`${imageUri}/info.json`, init, fetchFn);
}
export async function fetchImageBitmap(input, init, fetchFn) {
    const response = await fetchUrl(input, init, fetchFn);
    const blob = await response.blob();
    return await createImageBitmap(blob);
}
