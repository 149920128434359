export function getPropertyFromCacheOrComputation(cache, key, computation, useCache = true) {
    if (cache.has(key) && useCache) {
        return cache.get(key);
    }
    else {
        const result = computation();
        cache.set(key, result);
        return result;
    }
}
export function getPropertyFromDoubleCacheOrComputation(cache, key0, key1, computation, useCache = true) {
    if (cache.get(key0)?.has(key1) && useCache) {
        return cache.get(key0)?.get(key1);
    }
    else {
        const result = computation();
        if (!cache.get(key0)) {
            cache.set(key0, new Map());
        }
        cache.get(key0)?.set(key1, result);
        return result;
    }
}
