import { blue, darkblue, purple, pink, orange, red, green, yellow, gray, black, white, shades } from './colors.js';
function flattenShades(color, shades) {
    return shades.reduce((flattened, hex, index) => {
        const shade = (index + 1) * 100;
        const key = `${color}-${shade}`;
        flattened[key] = hex;
        return flattened;
    }, {});
}
export default {
    blue,
    darkblue,
    purple,
    pink,
    orange,
    red,
    green,
    yellow,
    gray,
    black,
    white,
    // Add shades
    ...flattenShades('blue', shades.blue),
    ...flattenShades('darkblue', shades.darkblue),
    ...flattenShades('purple', shades.purple),
    ...flattenShades('pink', shades.pink),
    ...flattenShades('orange', shades.orange),
    ...flattenShades('red', shades.red),
    ...flattenShades('green', shades.green),
    ...flattenShades('yellow', shades.yellow),
    ...flattenShades('gray', shades.gray)
};
